.home-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    cursor: none;
    animation: fadein 1s linear forwards;
    -moz-animation: fadein 1s linear forwards;
    -webkit-animation: fadein 1s linear forwards;
    -o-animation: fadein 1s linear forwards;
}
