.main-container {
    width: 50vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
}

.main-container.left {
    background: url(../../img/bg-left.jpg),
        url(../../img/fabric_texture_dark.png);
    background-position: left bottom;
    background-repeat: no-repeat, repeat;
    background-size: auto 100vh, auto auto;
    background-blend-mode: multiply;
    color: #e2e8e8;
}

.main-container.right {
    background: url(../../img/bg-right.jpg), url(../../img/fabric_texture.png);
    background-position: right bottom;
    background-repeat: no-repeat, repeat;
    background-size: auto 100vh, auto auto;
    background-blend-mode: multiply;
    color: #2c2f2f;
}

.cover.left {
    content: "";
    background: url(../../img/fabric_texture_dark.png) #2c2f2f repeat;
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 50vw;
    left: 0;
    animation: fadein 0.5s linear forwards;
    -moz-animation: fadein 0.5s linear forwards;
    -o-animation: fadein 0.5s linear forwards;
    -webkit-animation: fadein 0.5s linear forwards;
}

.cover.right {
    content: "";
    background: url(../../img/fabric_texture.png) #e2e8e8 repeat;
    background-blend-mode: multiply;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 50vw;
    animation: fadein 0.5s linear forwards;
    -moz-animation: fadein 0.5s linear forwards;
    -o-animation: fadein 0.5s linear forwards;
    -webkit-animation: fadein 0.5s linear forwards;
}

.cover.right.transparent {
    animation: fadeout 0.5s linear forwards;
    -moz-animation: fadeout 0.5s linear forwards;
    -o-animation: fadeout 0.5s linear forwards;
    -webkit-animation: fadeout 0.5s linear forwards;
}

.cover.left.transparent {
    animation: fadeout 0.5s linear forwards;
    -moz-animation: fadeout 0.5s linear forwards;
    -o-animation: fadeout 0.5s linear forwards;
    -webkit-animation: fadeout 0.5s linear forwards;
}

h1,
h2 {
    background: none !important;
    z-index: 10;
}

#name {
    margin-top: 15vh;
    font-size: 6vh;
}

#name.left {
    text-align: right;
    margin-right: 1vw;
}

#name.right {
    text-align: left;
    margin-left: 1vw;
}

.button {
    padding: 1vh 1vw;
    margin: 1vh 1vw;
    border-radius: 0.5vh;
    width: 10vw;
    z-index: 10;
    font-size: 1vh;
}

#about.button {
    align-self: flex-end;
    border: solid 1px #e2e8e8;
    color: #e2e8e8;
    background: none;
}

#about.button:hover {
    border: solid 1px transparent;
    color: #2c2f2f;
    background-color: #e2e8e8;
    cursor: none;
}

#contact.button {
    align-self: flex-start;
    border: solid 1px #2c2f2f;
    color: #2c2f2f;
    background: none;
}

#contact.button:hover {
    border: solid 1px transparent;
    color: #e2e8e8;
    background-color: #2c2f2f;
    cursor: none;
}

.title-container {
    align-self: center;
    text-align: center;
    margin-top: 50vh;
    z-index: 10;
    border-radius: 1vh;
}

.title-container.left {
    animation: leftbuttonoff 0.5s;
    -moz-animation: leftbuttonoff 0.5s;
    -webkit-animation: leftbuttonoff 0.5s;
    -o-animation: leftbuttonoff 0.5s;
}

.title-container.right {
    animation: rightbuttonoff 0.5s;
    -moz-animation: rightbuttonoff 0.5s;
    -webkit-animation: rightbuttonoff 0.5s;
    -o-animation: rightbuttonoff 0.5s;
}

.title-container.right.transparent {
    background-color: rgba(226, 232, 232, 0.7);
    animation: rightbuttonon 0.5s;
    -moz-animation: rightbuttonon 0.5s;
    -webkit-animation: rightbuttonon 0.5s;
    -o-animation: rightbuttonon 0.5s;
}

.title-container.left.transparent {
    background-color: rgba(44, 47, 47, 0.7);
    animation: leftbuttonon 0.5s;
    -moz-animation: leftbuttonon 0.5s;
    -webkit-animation: leftbuttonon 0.5s;
    -o-animation: leftbuttonon 0.5s;
}

#title {
    padding: 1vh 2vw;
}

/* ANIMATIONS */

@keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@-webkit-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@-moz-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}
@-o-keyframes fadeout {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes rightbuttonon {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(226, 232, 232, 0.7);
    }
}
@-o-keyframes rightbuttonon {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(226, 232, 232, 0.7);
    }
}
@-webkit-keyframes rightbuttonon {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(226, 232, 232, 0.7);
    }
}
@-moz-keyframes rightbuttonon {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(226, 232, 232, 0.7);
    }
}

@keyframes rightbuttonoff {
    from {
        background-color: rgba(226, 232, 232, 0.7);
    }
    to {
        background-color: transparent;
    }
}
@-o-keyframes rightbuttonoff {
    from {
        background-color: rgba(226, 232, 232, 0.7);
    }
    to {
        background-color: transparent;
    }
}
@-webkit-keyframes rightbuttonoff {
    from {
        background-color: rgba(226, 232, 232, 0.7);
    }
    to {
        background-color: transparent;
    }
}
@-moz-keyframes rightbuttonoff {
    from {
        background-color: rgba(226, 232, 232, 0.7);
    }
    to {
        background-color: transparent;
    }
}

@keyframes leftbuttonon {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(44, 47, 47, 0.7);
    }
}
@-o-keyframes leftbuttonon {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(44, 47, 47, 0.7);
    }
}
@-webkit-keyframes leftbuttonon {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(44, 47, 47, 0.7);
    }
}
@-moz-keyframes leftbuttonon {
    from {
        background-color: transparent;
    }
    to {
        background-color: rgba(44, 47, 47, 0.7);
    }
}

@keyframes leftbuttonoff {
    from {
        background-color: rgba(44, 47, 47, 0.7);
    }
    to {
        background-color: transparent;
    }
}
@-o-keyframes leftbuttonoff {
    from {
        background-color: rgba(44, 47, 47, 0.7);
    }
    to {
        background-color: transparent;
    }
}
@-webkit-keyframes leftbuttonoff {
    from {
        background-color: rgba(44, 47, 47, 0.7);
    }
    to {
        background-color: transparent;
    }
}
@-moz-keyframes leftbuttonoff {
    from {
        background-color: rgba(44, 47, 47, 0.7);
    }
    to {
        background-color: transparent;
    }
}
