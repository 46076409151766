.mobile-container {
    width: 100vw;
    overflow-y: hidden;
    cursor: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation: fadein 1s linear forwards;
    -moz-animation: fadein 1s linear forwards;
    -webkit-animation: fadein 1s linear forwards;
    -o-animation: fadein 1s linear forwards;
}

.dev-half {
    background: url("../../img/bg-left-m.jpg") no-repeat;
    background-position: left top;
    background-size: auto 50vh;
    height: 50%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.des-half {
    background: url("../../img/bg-right-m.jpg") no-repeat;
    background-position: left top;
    background-size: auto 50vh;
    height: 50%;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
}

.divider {
    background-color: #9a9e9e;
    height: 5px;
    width: 100vw;
}

.name-mobile {
    position: fixed;
    /* margin-top: 65%; */
    background-color: #9a9e9e;
    padding: 1vh 2vw;
    border-radius: 3vw;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 20vw #111111;
}

.name-mobile-text {
    color: #2c2f2f;
    font-size: 6vw;
    padding-top: 0.5vh;
    margin-left: 1vw;
}

.about-mobile {
    border: none;
    background-color: #2c2f2f;
    color: #9a9e9e;
    padding: 1vh 3vh 1vh 3vh;
    border-radius: 1vh;
    margin-left: 3vw;
    margin-right: 1vw;
    display: flex;
    font-size: 1vh;
}

.title-mobile {
    padding: 8vw;
    border-radius: 2vh;
    background-color: rgba(226, 232, 232, 0.4);
    box-shadow: 0 0 20vw #111111;
}

.title-mobile-text {
    text-align: center;
    color: #2c2f2f;
    font-size: 6vw;
}
