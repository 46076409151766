.slider-container {
    width: 80vh;
    height: 45vh;
}

.btn-prev {
    width: 5vh;
    height: 10vh;
    padding: 0.1vh 0.2vh 0.1vh 0.1vh;
    border-radius: 0 1.5vh 1.5vh 0;
    background-color: rgba(20, 20, 20, 0.8);
    z-index: 999;
    border: none;
    position: absolute;
    top: 38%;
    left: 0;
    cursor: none;
}

.btn-next {
    width: 5vh;
    height: 10vh;
    padding: 0.1vh 0.2vh 0.1vh 0.1vh;
    border-radius: 0 1.5vh 1.5vh 0;
    background-color: rgba(20, 20, 20, 0.8);
    z-index: 999;
    border: none;
    position: absolute;
    top: 38%;
    right: 0;
    transform: rotate(180deg);
    cursor: none;
}

.btn-hidden {
    display: none;
}

.slider-image {
    width: 80vh !important;
    height: 45vh !important;
}

@media (max-width: 800px) {
    .slider-container {
        width: 90vw;
        height: 50vw;
    }

    .slider-image {
        width: 90vw !important;
        height: 50vw !important;
    }

    .btn-next {
        cursor: pointer !important;
    }

    .btn-prev {
        cursor: pointer !important;
    }
}
