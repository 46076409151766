.index-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #2c2f2f url(../../img/fabric_texture_dark.png) repeat;
    background-blend-mode: hard-light;
    animation: fadein 1s linear forwards;
    -moz-animation: fadein 1s linear forwards;
    -webkit-animation: fadein 1s linear forwards;
    -o-animation: fadein 1s linear forwards;
    cursor: auto !important;
    padding: 5vh auto;
}

.section-button {
    background: #e2e8e8 url(../../img/fabric_texture.png) repeat;
    border-radius: 1vh;
    width: 60vw;
    height: 10vh;
    margin: 2vh;
    cursor: pointer;
    border: none;
    animation: glowout 500ms forwards;
    -moz-animation: glowout 500ms forwards;
    -o-animation: glowout 500ms forwards;
    -webkit-animation: glowout 500ms forwards;
}

.section-button:hover {
    animation: glow 1s forwards;
    -moz-animation: glow 1s forwards;
    -o-animation: glow 1s forwards;
    -webkit-animation: glow 1s forwards;
}

.section-title {
    color: #2c2f2f;
    font-size: 3vh;
}

@keyframes glow {
    from {
        box-shadow: 0 0 5vh #111111;
    }
    to {
        box-shadow: 0 0 1vh #49c0b6;
    }
}
@-webkit-keyframes glow {
    from {
        box-shadow: 0 0 5vh #111111;
    }
    to {
        box-shadow: 0 0 7vh #49c0b6;
    }
}
@-moz-keyframes glow {
    from {
        box-shadow: 0 0 5vh #111111;
    }
    to {
        box-shadow: 0 0 7vh #49c0b6;
    }
}
@-o-keyframes glow {
    from {
        box-shadow: 0 0 5vh #111;
    }
    to {
        box-shadow: 0 0 7vh #49c0b6;
    }
}

@keyframes glowout {
    from {
        box-shadow: 0 0 1vh #49c0b6;
    }
    to {
        box-shadow: 0 0 5vh #111111;
    }
}
@-webkit-keyframes glowout {
    from {
        box-shadow: 0 0 1vh #49c0b6;
    }
    to {
        box-shadow: 0 0 5vh #111111;
    }
}
@-moz-keyframes glowout {
    from {
        box-shadow: 0 0 1vh #49c0b6;
    }
    to {
        box-shadow: 0 0 5vh #111111;
    }
}
@-o-keyframes glowout {
    from {
        box-shadow: 0 0 1vh #49c0b6;
    }
    to {
        box-shadow: 0 0 5vh #111111;
    }
}

@media (max-width: 800px) {
    .index-container {
        height: 90vh;
    }
}
