.pindex-container {
    width: 100vw;
    height: 100vh;
    -ms-overflow-style: none;
    scrollbar-width: none;
    animation: fadein 1s linear forwards;
    -moz-animation: fadein 1s linear forwards;
    -webkit-animation: fadein 1s linear forwards;
    -o-animation: fadein 1s linear forwards;
}

::-webkit-scrollbar {
    display: none;
    width: 0px;
    background: transparent;
}

.projects-title {
    text-align: center;
    position: fixed;
    left: 20vw;
    top: 4vh;
    width: 60vw;
    color: #e2e8e8;
    font-size: 3vh;
}

.navigate {
    position: fixed;
    bottom: 2vh;
    left: 40vw;
}

.navigate-img {
    height: auto;
    width: 20vw;
}

.project-card-container {
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
    overflow: hidden;
}

.prev {
    display: flex;
    align-items: center;
    height: 20vh;
    width: 20vh;
    position: fixed;
    top: 40vh;
    left: -4vh;
}

.next {
    display: flex;
    align-items: center;
    height: 20vh;
    width: 20vh;
    position: fixed;
    top: 40vh;
    right: -4vh;
    transform: rotate(180deg);
}

.prev-text {
    display: flex;
    align-items: center;
    height: 20vh;
    position: fixed;
    top: 40vh;
    left: 10vh;
    color: rgba(226, 232, 232, 0.2);
    text-align: left;
    z-index: -5;
}

.next-text {
    display: flex;
    align-items: center;
    height: 20vh;
    position: fixed;
    top: 40vh;
    right: 10vh;
    color: rgba(226, 232, 232, 0.2);
    text-align: right;
    z-index: -5;
}

@media (max-width: 800px) {
    .pindex-container {
        height: auto;
        cursor: auto !important;
    }

    .project-card-container {
        display: flex;
        flex-direction: column;
        padding: 5vw;
    }

    .prev,
    .next,
    .next-text,
    .prev-text,
    .navigate {
        display: none;
    }

    .projects-title {
        left: 12vw;
        width: 80vw;
        font-size: 3vw;
        top: 4.5vw;
        z-index: -999;
    }

    .project-card:first-of-type {
        margin-top: 8vw !important;
    }
}
