.about-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    animation: fadein 1s linear forwards;
    -moz-animation: fadein 1s linear forwards;
    -webkit-animation: fadein 1s linear forwards;
    -o-animation: fadein 1s linear forwards;
    cursor: auto !important;
}

.text-canvas {
    display: flex;
    width: 80vw;
    height: auto;
    background: #e2e8e8 url(../../img/fabric_texture.png) repeat;
    box-shadow: 0 0 10vh #111111;
    border-radius: 10px;
    margin-top: -10vh;
}

.text-container {
    margin: 10vh 5vh;
}

.picture-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15vw;
    height: 15vw;
    border-radius: 10vw;
    overflow: hidden;
    box-shadow: 0 0 5vh #111111;
    border: solid 1vh #e2e8e8;
    z-index: 5;
}

.picture {
    width: 15vw;
    height: 15vw;
}

.about-title {
    font-size: 3vh;
    color: #2c2f2f;
}

.about-title:last-of-type {
    margin-top: 3vh;
}

.about-text {
    font-size: 2vh;
    color: #2c2f2f;
    margin-top: 1.5vh;
}

.social-buttons {
    display: inline;
    list-style-type: none;
}

.sm-buttons {
    float: left;
    width: 2.5vh;
    height: 2.5vh;
    margin: 2vh 0;
    padding: 0 1vh 1vh 1vh;
    animation: mouseout 1s linear forwards;
    -moz-animation: mouseout 1s linear forwards;
    -webkit-animation: mouseout 1s linear forwards;
    -o-animation: mouseout 1s linear forwards;
}

.sm-buttons:hover {
    animation: mousein 0.5s linear forwards;
    -moz-animation: mousein 0.5s linear forwards;
    -webkit-animation: mousein 0.5s linear forwards;
    -o-animation: mousein 0.5s linear forwards;
    cursor: pointer;
}

.skills {
    display: flex;
    flex-direction: row;
}

.skills-mobile {
    display: none;
}

@keyframes mousein {
    from {
        border-bottom: rgba(44, 47, 47, 0) 2px solid;
    }
    to {
        border-bottom: #49c0b6 solid 2px;
    }
}
@-moz-keyframes mousein {
    from {
        border-bottom: rgba(44, 47, 47, 0) 2px solid;
    }
    to {
        border-bottom: #49c0b6 solid 2px;
    }
}
@-webkit-keyframes mousein {
    from {
        border-bottom: rgba(44, 47, 47, 0) 2px solid;
    }
    to {
        border-bottom: #49c0b6 solid 2px;
    }
}
@-o-keyframes mousein {
    from {
        border-bottom: rgba(44, 47, 47, 0) 2px solid;
    }
    to {
        border-bottom: #49c0b6 solid 2px;
    }
}

@keyframes mouseout {
    from {
        border-bottom: #49c0b6 solid 2px;
    }
    to {
        border-bottom: rgba(44, 47, 47, 0) 2px solid;
    }
}
@-o-keyframes mouseout {
    from {
        border-bottom: #49c0b6 solid 2px;
    }
    to {
        border-bottom: rgba(44, 47, 47, 0) 2px solid;
    }
}
@-webkit-keyframes mouseout {
    from {
        border-bottom: #49c0b6 solid 2px;
    }
    to {
        border-bottom: rgba(44, 47, 47, 0) 2px solid;
    }
}
@-moz-keyframes mouseout {
    from {
        border-bottom: #49c0b6 solid 2px;
    }
    to {
        border-bottom: rgba(44, 47, 47, 0) 2px solid;
    }
}

@media (max-width: 800px) {
    .about-container {
        height: 90vh;
    }

    .text-canvas {
        width: 90vw;
        height: auto;
        margin-top: 0;
    }

    .text-container {
        max-width: 80vw;
        margin: 8vw 5vw;
    }

    .picture {
        width: 15vh;
        height: 15vh;
    }

    .picture-frame {
        width: 15vh;
        height: 15vh;
        border-radius: 15vh;
        border: solid 1vw #e2e8e8;
        margin-top: 3vh;
        margin-bottom: -3vh;
    }

    .about-title {
        font-size: 5vw;
        color: #2c2f2f;
    }

    .about-title:last-of-type {
        margin-top: 3vh;
    }

    .about-text {
        font-size: 3vw;
        color: #2c2f2f;
        margin-top: 1.5vh;
    }

    .skills-mobile {
        display: flex;
    }

    .skills {
        display: none;
    }
}

@media (max-width: 1130px) {
    .skills-mobile {
        display: flex;
    }

    .skills {
        display: none;
    }
}
