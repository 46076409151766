.project-card {
    width: 80vh;
    height: 75vh;
    background: #e2e8e8 url(../../img/fabric_texture.png) repeat;
    box-shadow: 0 0 5vh #111;
    border-radius: 1vh;
    overflow: hidden;
    margin: 12vh 0 8vh 0;
    display: flex;
    flex-direction: column;
}

.project-body {
    padding: 3vh 3vh;
    display: flex;
    flex-direction: column;
    height: 30vh;
}

.project-title-container {
    display: flex;
    justify-content: space-between;
}

.project-title {
    font-size: 2vh;
    color: rgba(44, 47, 47, 0.9);
    padding-top: 0.8vh;
}

.project-tech {
    padding: 0.5vh;
}

.project-description {
    color: rgba(44, 47, 47, 0.9);
    padding: 1vh 0 1.5vh 0;
    height: 5vh;
}

.project-description-text {
    font-size: 1.8vh;
}

.project-extras {
    display: flex;
    align-items: center;
    padding: 0.7vh;
}

.project-extras-text {
    color: rgba(44, 47, 47, 0.9);
    padding: 0.3vh 0 0 0.8vh;
}

.project-icon {
    width: 2vh;
    height: 2vh;
}

.project-buttons {
    width: 74vh;
    height: 4vh;
    display: flex;
    margin-top: 1vh;
}

.project-btn {
    margin-right: 1vh;
    border-radius: 0.5vh;
    display: flex;
    align-items: center;
    padding: 0.5vh 1.5vh 0.5vh 0;
    border: solid 0.2vh rgba(44, 47, 47, 0.7);
    background-color: #e2e8e8;
    cursor: pointer;
    overflow: hidden;
    animation: shadowout 0.3s linear forwards;
    -o-animation: shadowout 0.3s linear forwards;
    -moz-animation: shadowout 0.3s linear forwards;
    -webkit-animation: shadowout 0.3s linear forwards;
    cursor: none;
}

.project-btn:hover {
    animation: shadowin 0.3s linear forwards;
    -o-animation: shadowin 0.3s linear forwards;
    -moz-animation: shadowin 0.3s linear forwards;
    -webkit-animation: shadowin 0.3s linear forwards;
}

.disabled {
    background-color: rgba(44, 47, 47, 0.3);
    border-color: rgba(44, 47, 47, 0.3);
    color: rgba(44, 47, 47, 0.3);
    box-shadow: none;
}

.disabled:hover {
    box-shadow: none !important;
}

.project-btn:last-of-type {
    margin-right: 0;
}

.btn-icon-box {
    border-radius: 0.5vh;
    padding: 0.5vh;
}

.btn-icon {
    height: 2.5vh;
    width: 2.5vh;
}

.btn-label {
    padding-top: 0.3vh;
    margin-left: 0.5vh;
    text-transform: uppercase;
    font-size: 1.3vh;
    color: #2c2f2f;
}

.project-tech {
    display: flex;
}

.tech-box {
    border-radius: 0.7vh;
    margin-left: 0.5vh;
    border: solid 2px #2c2f2f;
    overflow: hidden;
}

.tech-box.icon {
    width: 2.5vh;
    height: 2.5vh;
}

.tech-icon {
    width: 2vh;
    height: 2vh;
    padding-top: 0.3vh;
    margin: auto;
}

.tech-logo-text {
    font-size: "Gotham Bold", sans-serif;
    font-size: 1.1vh;
    text-align: center;
    padding: 0.7vh 0.5vh 0.5vh 0.5vh;
}

@keyframes shadowin {
    from {
        box-shadow: 0 0 0.5vh;
    }
    to {
        box-shadow: 0 0 1vh;
    }
}
@-webkit-keyframes shadowin {
    from {
        box-shadow: 0 0 0.5vh;
    }
    to {
        box-shadow: 0 0 1vh;
    }
}
@-moz-keyframes shadowin {
    from {
        box-shadow: 0 0 0.5vh;
    }
    to {
        box-shadow: 0 0 1vh;
    }
}
@-o-keyframes shadowin {
    from {
        box-shadow: 0 0 0.5vh;
    }
    to {
        box-shadow: 0 0 1vh;
    }
}

@keyframes shadowout {
    from {
        box-shadow: 0 0 1vh;
    }
    to {
        box-shadow: 0 0 0.5vh;
    }
}
@-webkit-keyframes shadowout {
    from {
        box-shadow: 0 0 1vh;
    }
    to {
        box-shadow: 0 0 0.5vh;
    }
}
@-moz-keyframes shadowout {
    from {
        box-shadow: 0 0 1vh;
    }
    to {
        box-shadow: 0 0 0.5vh;
    }
}
@-o-keyframes shadowout {
    from {
        box-shadow: 0 0 1vh;
    }
    to {
        box-shadow: 0 0 0.5vh;
    }
}

@media (max-width: 800px) {
    .project-card {
        width: 90vw;
        height: auto;
        margin: 0 0 3vh 0 !important;
    }

    .project-body {
        height: auto;
    }

    .project-title-container {
        display: flex;
        flex-direction: column;
    }

    .project-description {
        display: none;
    }

    .project-title {
        font-size: 4.5vw;
        padding: 0 0 0.5vh 0;
    }

    .project-extras {
        padding-left: 0;
    }

    .project-extras-text {
        font-size: 3.5vw;
        max-width: 60vw;
    }

    .project-icon {
        width: 7vw;
        height: 7vw;
    }

    .project-buttons {
        margin-bottom: 1vh;
        width: 70vw;
        height: auto;
        flex-wrap: wrap;
    }

    .project-btn {
        margin-top: 1vh;
        cursor: pointer !important;
    }

    .project-tech {
        flex-wrap: wrap;
        margin-left: -1vh;
    }

    .tech-box {
        margin-top: 0.5vh;
    }
}
